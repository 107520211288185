import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import OfficeUserMappingAdd from "@components/office-user-mapping/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "office-user-mapping.create"
}

const OfficeUserMappingAddPage = (props) => (
  <>
    <Seo title="Add Office-User Mapping" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings"
        action="Add" />
      <OfficeUserMappingAdd
        menu="Add Office-User Mapping"
        parentPath="office-user-mapping"
        pageType="Add"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default OfficeUserMappingAddPage;
