import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  GetAllItems
} from "../../utility/Form";
import Select from 'react-select';
import { set, isEmpty, get, find } from "lodash";
import CommonAddItemComp from "../common/Add";
import FormFooter from "../common/FormFooter";

const AddFormControls = (props) => {
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    admin_users: []
  });
  const [offices, setOffices] = useState([]);
  const [office, setOffice] = useState({});
  const GetOffices = async () => {
    try {
      const { data } = await GetAllItems('offices.getAll', {
        isGQL: true,
        query: `where: {_limit: -1}, sort: "Office_Name:ASC", publicationState: PREVIEW`,
        fields: `id, Office_Name, my_home_id, Branch_Location, Publish, published_at`
      });
      setOffices(data?.data.offices?.map(office => {
        return {
          value: office.id,
          label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
          isDisabled: (!office.Publish) || isEmpty(office.published_at) || (!office['my_home_id'])
        }
      }));
    } catch (error) {
      console.log('AddFormControls GetOffices error', error);
    }
  };
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const GetUsers = async () => {
    try {
      const { data: { data } } = await GetAllItems('users.getAll',
        { query: "_limit=-1&_sort=firstname:ASC" });
      setUsers(data?.results?.filter(user => (user.email.indexOf('@starberry') <= -1)).map(user => {
        return {
          value: user.id, label: `${user['firstname']} ${user['lastname']}`,
          isDisabled: (!user.isActive || user.blocked || find(user.roles, function (role) {
            return (role.name === 'Super Admin')
          }))
        }
      }));
    } catch (error) {
      console.log('AddFormControls GetUsers error', error);
    }
  };
  const GetUsersByOffice = async (officeId) => {
    try {
      const { data } = await GetAllItems('office-user-mapping.getUsersByOfficeId',
        { query: `officeId=${officeId}` });
      setUser(data?.map(user => {
        return {
          value: user.id, label: `${user['firstname']} ${user['lastname']}`,
          isDisabled: (!user.isActive || user.blocked || find(user.roles, function (role) {
            return (role.name === 'Super Admin')
          }))
        }
      }))
    } catch (error) {
      console.log('AddFormControls GetUsers error', error);
    }
  };

  const HandleMultiSelect = (event, name, copyParam) => {
    if (copyParam)
      set(DefaultFields, `${name}`, event.map(option => option.value));
    switch (name) {
      case 'office':
        setUser([]);
        setOffice(event);
        if (event.value) {
          GetUsersByOffice(event.value);
        }
        break;
      case 'admin_users':
        setUser(event);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    GetOffices();
    GetUsers();
  }, []);

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'add-office-user-mapping-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      if (get(Result, 'formSubmit.hasError')) {
        //setFromError(Result.formSubmit.error);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  return (
    <>
      <Form id="add-office-user-mapping-form" noValidate onSubmit={(event) => FormSubmitFunc(event, {
        isSubmit: true,
        isPageRedirect: true,
        // publish: true,
        parentPath: props.parentPath,
        pageRedirectUrl: `/${props.parentPath}/list`
      })}>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Office</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="office"
                  options={offices}
                  placeholder="Please select"
                  classNamePrefix={"select-opt"}
                  isSearchable={true}
                  value={office}
                  onChange={(event) => HandleMultiSelect(event, 'office')}
                />
              </div>
            </div>
          </Col>
        </Row>
        {
          office.value && <Row>
            <Col lg={9} className="mb-4">
              <div className="form-wrapper">
                <label>Users</label>
                <div className="custom-selectbox-form selectbox-dropdown">
                  <Select
                    name="admin_users"
                    options={users}
                    placeholder="Please select"
                    classNamePrefix={"select-opt"}
                    isSearchable={true}
                    isMulti={true}
                    value={user}
                    onChange={(event) => HandleMultiSelect(event, "admin_users", true)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        }
        <FormFooter parentPath={props.parentPath}></FormFooter>
      </Form>
    </>
  )
}

const AddOfficeUserMapping = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls
            HandleSubmitFunc={HandleSubmitFunc}
            {...props}
          />
        </>
      )
    }
  } {...props} />
}

export default AddOfficeUserMapping;
